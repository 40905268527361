import api from "./api";

var apiData = {};

apiData.getUserClients = () => {
	return new Promise((resolve, reject) => {
		api.get(`/users/${api.oauth.getAuth().user.id}/clients/`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getClient = (clientId, userId = 0, userExcept = false) => {
	return new Promise((resolve, reject) => {
		api.get(
			`/client/${clientId}?userException=${userExcept}&userId=${userId}`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getAdvertisingTypes = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/advertisingtypes")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getBrands = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(searchtext ? `/shared/brands/${searchtext}` : "/shared/brands")
			.then((response) => {
				// console.log('RESPONSE BRANDS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getCategories = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/shared/categories/${searchtext}`
				: "/shared/categories"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getCountries = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext ? `/shared/countries/${searchtext}` : "/shared/countries"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getMediaTypes = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/mediatypes")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getSubCategories = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/shared/subcategories/${searchtext}`
				: "/shared/subcategories"
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getTopics = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(searchtext ? `/shared/topics/${searchtext}` : "/shared/topics")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getTypes = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/types")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getSubscriptions = () => {
	return new Promise((resolve, reject) => {
		api.get("/shared/subscriptions")
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getAnalytics = (filters) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/getData`, filters)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getGridData = (
	filters,
	pagenumber,
	pagesize,
	filterField,
	filterValue,
	sortExp
) => {
	return new Promise((resolve, reject) => {
		api.post(
			`/analytics/getgriddata?pageNumber=${pagenumber}&pageSize=${pagesize}&filterField=${filterField}&filterValue=${filterValue}&sortExp=${sortExp}`,
			filters
		)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getExport = (filters) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/exportdata`, filters)
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getExcelExport = (definition) => {
	return new Promise((resolve, reject) => {
		api.post(`/analytics/exporttoexcel`, definition, 'blob')
			.then((response) => {
				// console.log('RESPONSE ANALYTICS: ', response.data)
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.createFlipbook = (details) => {
	return new Promise((resolve, reject) => {
		api.post(`/Flipbook`, details)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getFlipbook = (bookID) => {
	return new Promise((resolve, reject) => {
		api.get(`/Flipbook/${bookID}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateFlipbook = (id, details) => {
	return new Promise((resolve, reject) => {
		api.put(`/Flipbook/${id}`, details)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.shareFlipbook = (details) => {
	return new Promise((resolve, reject) => {
		api.post(`/Flipbook/share`, details)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteFlipbook = (id) => {
	return new Promise((resolve, reject) => {
		api.delete(`/Flipbook/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getAllFlipbooks = (userId = 0) => {
	return new Promise((resolve, reject) => {
		api.get(`/Flipbook/Flipbooks/${userId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.viewFlipbook = (id) => {
	return new Promise((resolve, reject) => {
		api.get(`/Flipbook/${id}/view`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getUsers = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/users/usersByFilter/${searchtext}`
				: `/users/usersByFilter`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateUser = (userDetails) => {
	return new Promise((resolve, reject) => {
		api.put(`/users/${userDetails.id}`, userDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.createUser = (userDetails) => {
	return new Promise((resolve, reject) => {
		api.post("/users", userDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.getUserClients = (id) => {
	return new Promise((resolve, reject) => {
		api.get(`/Users/${id}/clients`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.addUserClient = (userid, clientId) => {
	return new Promise((resolve, reject) => {
		api.post(`/users/${userid}/userclient/${clientId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteUser = (userid) => {
	return new Promise((resolve, reject) => {
		api.delete(`/users/${userid}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.deleteUserClient = (userid, clientId) => {
	return new Promise((resolve, reject) => {
		api.delete(`/users/${userid}/userclient/${clientId}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.getClients = (searchtext) => {
	return new Promise((resolve, reject) => {
		api.get(
			searchtext
				? `/client/clientsByFilter/${searchtext}`
				: `/client/clientsByFilter`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateClient = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.put(`/client/${clientDetails.id}`, clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

apiData.createClient = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.post("/client", clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteClient = (clientid) => {
	return new Promise((resolve, reject) => {
		api.delete(`/client/${clientid}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.put(`/client/${clientDetails.id}/config`, clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.createConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.post(`/client/${clientDetails.clientId}/config`, clientDetails)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.updateConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.put(
			clientDetails.userId
				? `/users/${clientDetails.userId}/userclient/${clientDetails.clientId}/config/${clientDetails.configId}`
				: `/client/${clientDetails.clientId}/config`,
			clientDetails
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
apiData.deleteConfig = (clientDetails) => {
	return new Promise((resolve, reject) => {
		api.delete(
			clientDetails.userId
				? `/users/${clientDetails.userId}/userclient/${clientDetails.clientId}/config/${clientDetails.configId}`
				: `/client/${clientDetails.clientId}/config/${clientDetails.configId}`
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export default apiData;
