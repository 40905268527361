import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import emitter from "tiny-emitter/instance";

export default function MainListItems(props) {
	const classes = useStyles();
	const [sandboxCounter, setSandboxCounter] = React.useState(0);
	const [bookbuilderCounter, setBookBuilderCounter] = React.useState(0);

	React.useEffect(() => {
		emitter.on("sandboxCounter", () => {
			const count = JSON.parse(sessionStorage.getItem("sandbox")).length;
			setSandboxCounter(count);
		});
		emitter.on("bookbuilderCounter", () => {
			const count = JSON.parse(sessionStorage.getItem("books")).length;
			setBookBuilderCounter(count);
		});

		return () => {
			emitter.off("sandboxCounter");
			emitter.off("bookbuilderCounter");
		};
	}, []); //eslint-disable-line

	return (
		<div>
			<ListItem
				button
				selected={props.activeRoute === "/overview"}
				component={(props) => <Link {...props} to={"/overview"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/overview" ? (
						<svg
							width="18"
							height="15"
							viewBox="0 0 18 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 6 }}
						>
							<path
								d="M9.00001 2.6145L13.2014 6.39575V12.9583H11.5208V7.91666H6.47917V12.9583H4.79862V6.39575L9.00001 2.6145ZM9.00001 0.354156L0.597229 7.91666H3.11806V14.6389H8.15973V9.59721H9.84029V14.6389H14.882V7.91666H17.4028L9.00001 0.354156Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="18"
							height="15"
							viewBox="0 0 18 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 6 }}
						>
							<path
								d="M9.00001 2.6145L13.2014 6.39575V12.9583H11.5208V7.91666H6.47917V12.9583H4.79862V6.39575L9.00001 2.6145ZM9.00001 0.354156L0.597229 7.91666H3.11806V14.6389H8.15973V9.59721H9.84029V14.6389H14.882V7.91666H17.4028L9.00001 0.354156Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/overview"
							? classes.textSelected
							: classes.text
					}
					primary="Overview"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/volume"}
				component={(props) => <Link {...props} to={"/volume"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/volume" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18 14.835L21.18 9.3375L22.4775 10.0875L18.555 16.875L13.6725 14.0625L10.095 20.25H22.5V21.75H7.5V8.25H9V19.155L13.125 12L18 14.835Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18 14.835L21.18 9.3375L22.4775 10.0875L18.555 16.875L13.6725 14.0625L10.095 20.25H22.5V21.75H7.5V8.25H9V19.155L13.125 12L18 14.835Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/volume"
							? classes.textSelected
							: classes.text
					}
					primary="Volume"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/mediaType"}
				component={(props) => <Link {...props} to={"/mediaType"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/mediaType" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M22.2378 9.075L21.2328 8.655V15.4275L23.0553 11.0325C23.3628 10.2675 23.0103 9.39 22.2378 9.075ZM7.61282 11.85L11.3328 20.8125C11.5653 21.39 12.1128 21.7425 12.6903 21.7575C12.8853 21.7575 13.0878 21.72 13.2828 21.6375L18.8103 19.35C19.3728 19.1175 19.7178 18.5625 19.7328 18.0075C19.7403 17.8125 19.7028 17.595 19.6353 17.4L15.8853 8.4375C15.6678 7.86 15.1128 7.5075 14.5278 7.5C14.3328 7.5 14.1378 7.545 13.9503 7.6125L8.43032 9.9C7.66532 10.215 7.29782 11.0925 7.61282 11.85ZM19.7253 9C19.7253 8.60218 19.5673 8.22064 19.286 7.93934C19.0047 7.65804 18.6231 7.5 18.2253 7.5H17.1378L19.7253 13.755"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M22.2378 9.075L21.2328 8.655V15.4275L23.0553 11.0325C23.3628 10.2675 23.0103 9.39 22.2378 9.075ZM7.61282 11.85L11.3328 20.8125C11.5653 21.39 12.1128 21.7425 12.6903 21.7575C12.8853 21.7575 13.0878 21.72 13.2828 21.6375L18.8103 19.35C19.3728 19.1175 19.7178 18.5625 19.7328 18.0075C19.7403 17.8125 19.7028 17.595 19.6353 17.4L15.8853 8.4375C15.6678 7.86 15.1128 7.5075 14.5278 7.5C14.3328 7.5 14.1378 7.545 13.9503 7.6125L8.43032 9.9C7.66532 10.215 7.29782 11.0925 7.61282 11.85ZM19.7253 9C19.7253 8.60218 19.5673 8.22064 19.286 7.93934C19.0047 7.65804 18.6231 7.5 18.2253 7.5H17.1378L19.7253 13.755"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/mediaType"
							? classes.textSelected
							: classes.text
					}
					primary="Media type"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/source"}
				component={(props) => <Link {...props} to={"/source"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/source" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18.75 14.25H17.25V12.75H18.75V14.25ZM15.75 14.25H14.25V12.75H15.75V14.25ZM12.75 14.25H11.25V12.75H12.75V14.25ZM21 7.5H9C8.60218 7.5 8.22064 7.65804 7.93934 7.93934C7.65804 8.22064 7.5 8.60218 7.5 9V22.5L10.5 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V9C22.5 8.1675 21.825 7.5 21 7.5Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18.75 14.25H17.25V12.75H18.75V14.25ZM15.75 14.25H14.25V12.75H15.75V14.25ZM12.75 14.25H11.25V12.75H12.75V14.25ZM21 7.5H9C8.60218 7.5 8.22064 7.65804 7.93934 7.93934C7.65804 8.22064 7.5 8.60218 7.5 9V22.5L10.5 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V9C22.5 8.1675 21.825 7.5 21 7.5Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/source"
							? classes.textSelected
							: classes.text
					}
					primary="Source"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/region"}
				component={(props) => <Link {...props} to={"/region"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/region" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.25 20.25L12.75 18.6675V9.75L17.25 11.3325V20.25ZM21.375 8.25C21.33 8.25 21.2925 8.25 21.255 8.25L17.25 9.825L12.75 8.25L8.52 9.675C8.3625 9.7275 8.25 9.8625 8.25 10.035V21.375C8.25 21.4745 8.28951 21.5698 8.35984 21.6402C8.43016 21.7105 8.52554 21.75 8.625 21.75C8.6625 21.75 8.7075 21.75 8.745 21.7275L12.75 20.175L17.25 21.75L21.48 20.325C21.6375 20.25 21.75 20.1375 21.75 19.965V8.625C21.75 8.52554 21.7105 8.43016 21.6402 8.35984C21.5698 8.28951 21.4745 8.25 21.375 8.25Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.25 20.25L12.75 18.6675V9.75L17.25 11.3325V20.25ZM21.375 8.25C21.33 8.25 21.2925 8.25 21.255 8.25L17.25 9.825L12.75 8.25L8.52 9.675C8.3625 9.7275 8.25 9.8625 8.25 10.035V21.375C8.25 21.4745 8.28951 21.5698 8.35984 21.6402C8.43016 21.7105 8.52554 21.75 8.625 21.75C8.6625 21.75 8.7075 21.75 8.745 21.7275L12.75 20.175L17.25 21.75L21.48 20.325C21.6375 20.25 21.75 20.1375 21.75 19.965V8.625C21.75 8.52554 21.7105 8.43016 21.6402 8.35984C21.5698 8.28951 21.4745 8.25 21.375 8.25Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/region"
							? classes.textSelected
							: classes.text
					}
					primary="Region"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/category"}
				component={(props) => <Link {...props} to={"/category"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/category" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.75 7.5V9.7725C18.2925 10.14 20.25 12.315 20.25 14.9625C20.25 15.6375 20.115 16.275 19.875 16.8675L21.84 18.015C22.26 17.085 22.5 16.05 22.5 14.9625C22.5 11.0775 19.5375 7.875 15.75 7.5ZM15 20.2125C13.6076 20.2125 12.2723 19.6594 11.2877 18.6748C10.3031 17.6902 9.75 16.3549 9.75 14.9625C9.75 12.315 11.7075 10.14 14.25 9.7725V7.5C10.455 7.875 7.5 11.07 7.5 14.9625C7.5 16.9516 8.29018 18.8593 9.6967 20.2658C10.3931 20.9622 11.2199 21.5147 12.1299 21.8916C13.0398 22.2685 14.0151 22.4625 15 22.4625C17.475 22.4625 19.6725 21.255 21.0375 19.395L19.0875 18.2475C18.1275 19.4625 16.6575 20.2125 15 20.2125Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.75 7.5V9.7725C18.2925 10.14 20.25 12.315 20.25 14.9625C20.25 15.6375 20.115 16.275 19.875 16.8675L21.84 18.015C22.26 17.085 22.5 16.05 22.5 14.9625C22.5 11.0775 19.5375 7.875 15.75 7.5ZM15 20.2125C13.6076 20.2125 12.2723 19.6594 11.2877 18.6748C10.3031 17.6902 9.75 16.3549 9.75 14.9625C9.75 12.315 11.7075 10.14 14.25 9.7725V7.5C10.455 7.875 7.5 11.07 7.5 14.9625C7.5 16.9516 8.29018 18.8593 9.6967 20.2658C10.3931 20.9622 11.2199 21.5147 12.1299 21.8916C13.0398 22.2685 14.0151 22.4625 15 22.4625C17.475 22.4625 19.6725 21.255 21.0375 19.395L19.0875 18.2475C18.1275 19.4625 16.6575 20.2125 15 20.2125Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/category"
							? classes.textSelected
							: classes.text
					}
					primary="Category share"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/language"}
				component={(props) => <Link {...props} to={"/language"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/language" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.9925 7.5C10.8525 7.5 7.5 10.86 7.5 15C7.5 19.14 10.8525 22.5 14.9925 22.5C19.14 22.5 22.5 19.14 22.5 15C22.5 10.86 19.14 7.5 14.9925 7.5ZM20.19 12H17.9775C17.7375 11.0625 17.3925 10.1625 16.9425 9.33C18.3225 9.8025 19.47 10.7625 20.19 12ZM15 9.03C15.6225 9.93 16.11 10.9275 16.4325 12H13.5675C13.89 10.9275 14.3775 9.93 15 9.03ZM9.195 16.5C9.075 16.02 9 15.5175 9 15C9 14.4825 9.075 13.98 9.195 13.5H11.73C11.67 13.995 11.625 14.49 11.625 15C11.625 15.51 11.67 16.005 11.73 16.5H9.195ZM9.81 18H12.0225C12.2625 18.9375 12.6075 19.8375 13.0575 20.67C11.6775 20.1975 10.53 19.245 9.81 18V18ZM12.0225 12H9.81C10.53 10.755 11.6775 9.8025 13.0575 9.33C12.6075 10.1625 12.2625 11.0625 12.0225 12V12ZM15 20.97C14.3775 20.07 13.89 19.0725 13.5675 18H16.4325C16.11 19.0725 15.6225 20.07 15 20.97ZM16.755 16.5H13.245C13.1775 16.005 13.125 15.51 13.125 15C13.125 14.49 13.1775 13.9875 13.245 13.5H16.755C16.8225 13.9875 16.875 14.49 16.875 15C16.875 15.51 16.8225 16.005 16.755 16.5ZM16.9425 20.67C17.3925 19.8375 17.7375 18.9375 17.9775 18H20.19C19.47 19.2375 18.3225 20.1975 16.9425 20.67V20.67ZM18.27 16.5C18.33 16.005 18.375 15.51 18.375 15C18.375 14.49 18.33 13.995 18.27 13.5H20.805C20.925 13.98 21 14.4825 21 15C21 15.5175 20.925 16.02 20.805 16.5H18.27Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.9925 7.5C10.8525 7.5 7.5 10.86 7.5 15C7.5 19.14 10.8525 22.5 14.9925 22.5C19.14 22.5 22.5 19.14 22.5 15C22.5 10.86 19.14 7.5 14.9925 7.5ZM20.19 12H17.9775C17.7375 11.0625 17.3925 10.1625 16.9425 9.33C18.3225 9.8025 19.47 10.7625 20.19 12ZM15 9.03C15.6225 9.93 16.11 10.9275 16.4325 12H13.5675C13.89 10.9275 14.3775 9.93 15 9.03ZM9.195 16.5C9.075 16.02 9 15.5175 9 15C9 14.4825 9.075 13.98 9.195 13.5H11.73C11.67 13.995 11.625 14.49 11.625 15C11.625 15.51 11.67 16.005 11.73 16.5H9.195ZM9.81 18H12.0225C12.2625 18.9375 12.6075 19.8375 13.0575 20.67C11.6775 20.1975 10.53 19.245 9.81 18V18ZM12.0225 12H9.81C10.53 10.755 11.6775 9.8025 13.0575 9.33C12.6075 10.1625 12.2625 11.0625 12.0225 12V12ZM15 20.97C14.3775 20.07 13.89 19.0725 13.5675 18H16.4325C16.11 19.0725 15.6225 20.07 15 20.97ZM16.755 16.5H13.245C13.1775 16.005 13.125 15.51 13.125 15C13.125 14.49 13.1775 13.9875 13.245 13.5H16.755C16.8225 13.9875 16.875 14.49 16.875 15C16.875 15.51 16.8225 16.005 16.755 16.5ZM16.9425 20.67C17.3925 19.8375 17.7375 18.9375 17.9775 18H20.19C19.47 19.2375 18.3225 20.1975 16.9425 20.67V20.67ZM18.27 16.5C18.33 16.005 18.375 15.51 18.375 15C18.375 14.49 18.33 13.995 18.27 13.5H20.805C20.925 13.98 21 14.4825 21 15C21 15.5175 20.925 16.02 20.805 16.5H18.27Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/language"
							? classes.textSelected
							: classes.text
					}
					primary="Language"
				/>
			</ListItem>
			{/* <ListItem
				button
				selected={props.activeRoute === "/lsmsem"}
				component={(props) => <Link {...props} to={"/lsmsem"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/lsmsem" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.75 14.8125C12.5014 14.8125 12.2629 14.9113 12.0871 15.0871C11.9113 15.2629 11.8125 15.5014 11.8125 15.75C11.8125 15.9986 11.9113 16.2371 12.0871 16.4129C12.2629 16.5887 12.5014 16.6875 12.75 16.6875C12.8731 16.6875 12.995 16.6633 13.1088 16.6161C13.2225 16.569 13.3259 16.5 13.4129 16.4129C13.5 16.3259 13.569 16.2225 13.6161 16.1088C13.6633 15.995 13.6875 15.8731 13.6875 15.75C13.6875 15.6269 13.6633 15.505 13.6161 15.3912C13.569 15.2775 13.5 15.1741 13.4129 15.0871C13.3259 15 13.2225 14.931 13.1088 14.8839C12.995 14.8367 12.8731 14.8125 12.75 14.8125ZM17.25 14.8125C17.0014 14.8125 16.7629 14.9113 16.5871 15.0871C16.4113 15.2629 16.3125 15.5014 16.3125 15.75C16.3125 15.9986 16.4113 16.2371 16.5871 16.4129C16.7629 16.5887 17.0014 16.6875 17.25 16.6875C17.4986 16.6875 17.7371 16.5887 17.9129 16.4129C18.0887 16.2371 18.1875 15.9986 18.1875 15.75C18.1875 15.5014 18.0887 15.2629 17.9129 15.0871C17.7371 14.9113 17.4986 14.8125 17.25 14.8125ZM15 7.5C14.0151 7.5 13.0398 7.69399 12.1299 8.0709C11.2199 8.44781 10.3931 9.00026 9.6967 9.6967C8.29018 11.1032 7.5 13.0109 7.5 15C7.5 16.9891 8.29018 18.8968 9.6967 20.3033C10.3931 20.9997 11.2199 21.5522 12.1299 21.9291C13.0398 22.306 14.0151 22.5 15 22.5C16.9891 22.5 18.8968 21.7098 20.3033 20.3033C21.7098 18.8968 22.5 16.9891 22.5 15C22.5 14.0151 22.306 13.0398 21.9291 12.1299C21.5522 11.2199 20.9997 10.3931 20.3033 9.6967C19.6069 9.00026 18.7801 8.44781 17.8701 8.0709C16.9602 7.69399 15.9849 7.5 15 7.5V7.5ZM15 21C11.6925 21 9 18.3075 9 15C9 14.7825 9 14.565 9.0375 14.355C10.8075 13.5675 12.21 12.12 12.945 10.3275C14.3025 12.2475 16.5375 13.5 19.065 13.5C19.65 13.5 20.2125 13.4325 20.7525 13.305C20.91 13.8375 21 14.4075 21 15C21 18.3075 18.3075 21 15 21Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.75 14.8125C12.5014 14.8125 12.2629 14.9113 12.0871 15.0871C11.9113 15.2629 11.8125 15.5014 11.8125 15.75C11.8125 15.9986 11.9113 16.2371 12.0871 16.4129C12.2629 16.5887 12.5014 16.6875 12.75 16.6875C12.8731 16.6875 12.995 16.6633 13.1088 16.6161C13.2225 16.569 13.3259 16.5 13.4129 16.4129C13.5 16.3259 13.569 16.2225 13.6161 16.1088C13.6633 15.995 13.6875 15.8731 13.6875 15.75C13.6875 15.6269 13.6633 15.505 13.6161 15.3912C13.569 15.2775 13.5 15.1741 13.4129 15.0871C13.3259 15 13.2225 14.931 13.1088 14.8839C12.995 14.8367 12.8731 14.8125 12.75 14.8125ZM17.25 14.8125C17.0014 14.8125 16.7629 14.9113 16.5871 15.0871C16.4113 15.2629 16.3125 15.5014 16.3125 15.75C16.3125 15.9986 16.4113 16.2371 16.5871 16.4129C16.7629 16.5887 17.0014 16.6875 17.25 16.6875C17.4986 16.6875 17.7371 16.5887 17.9129 16.4129C18.0887 16.2371 18.1875 15.9986 18.1875 15.75C18.1875 15.5014 18.0887 15.2629 17.9129 15.0871C17.7371 14.9113 17.4986 14.8125 17.25 14.8125ZM15 7.5C14.0151 7.5 13.0398 7.69399 12.1299 8.0709C11.2199 8.44781 10.3931 9.00026 9.6967 9.6967C8.29018 11.1032 7.5 13.0109 7.5 15C7.5 16.9891 8.29018 18.8968 9.6967 20.3033C10.3931 20.9997 11.2199 21.5522 12.1299 21.9291C13.0398 22.306 14.0151 22.5 15 22.5C16.9891 22.5 18.8968 21.7098 20.3033 20.3033C21.7098 18.8968 22.5 16.9891 22.5 15C22.5 14.0151 22.306 13.0398 21.9291 12.1299C21.5522 11.2199 20.9997 10.3931 20.3033 9.6967C19.6069 9.00026 18.7801 8.44781 17.8701 8.0709C16.9602 7.69399 15.9849 7.5 15 7.5V7.5ZM15 21C11.6925 21 9 18.3075 9 15C9 14.7825 9 14.565 9.0375 14.355C10.8075 13.5675 12.21 12.12 12.945 10.3275C14.3025 12.2475 16.5375 13.5 19.065 13.5C19.65 13.5 20.2125 13.4325 20.7525 13.305C20.91 13.8375 21 14.4075 21 15C21 18.3075 18.3075 21 15 21Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/lsmsem"
							? classes.textSelected
							: classes.text
					}
					primary="LSM / SEM"
				/>
			</ListItem> */}
			<ListItem
				button
				selected={props.activeRoute === "/value"}
				component={(props) => <Link {...props} to={"/value"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/value" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.75 9H23.25V18H9.75V9ZM16.5 11.25C17.0967 11.25 17.669 11.4871 18.091 11.909C18.5129 12.331 18.75 12.9033 18.75 13.5C18.75 14.0967 18.5129 14.669 18.091 15.091C17.669 15.5129 17.0967 15.75 16.5 15.75C15.9033 15.75 15.331 15.5129 14.909 15.091C14.4871 14.669 14.25 14.0967 14.25 13.5C14.25 12.9033 14.4871 12.331 14.909 11.909C15.331 11.4871 15.9033 11.25 16.5 11.25V11.25ZM12.75 10.5C12.75 10.8978 12.592 11.2794 12.3107 11.5607C12.0294 11.842 11.6478 12 11.25 12V15C11.6478 15 12.0294 15.158 12.3107 15.4393C12.592 15.7206 12.75 16.1022 12.75 16.5H20.25C20.25 16.1022 20.408 15.7206 20.6893 15.4393C20.9706 15.158 21.3522 15 21.75 15V12C21.3522 12 20.9706 11.842 20.6893 11.5607C20.408 11.2794 20.25 10.8978 20.25 10.5H12.75ZM6.75 12H8.25V19.5H20.25V21H6.75V12Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.75 9H23.25V18H9.75V9ZM16.5 11.25C17.0967 11.25 17.669 11.4871 18.091 11.909C18.5129 12.331 18.75 12.9033 18.75 13.5C18.75 14.0967 18.5129 14.669 18.091 15.091C17.669 15.5129 17.0967 15.75 16.5 15.75C15.9033 15.75 15.331 15.5129 14.909 15.091C14.4871 14.669 14.25 14.0967 14.25 13.5C14.25 12.9033 14.4871 12.331 14.909 11.909C15.331 11.4871 15.9033 11.25 16.5 11.25V11.25ZM12.75 10.5C12.75 10.8978 12.592 11.2794 12.3107 11.5607C12.0294 11.842 11.6478 12 11.25 12V15C11.6478 15 12.0294 15.158 12.3107 15.4393C12.592 15.7206 12.75 16.1022 12.75 16.5H20.25C20.25 16.1022 20.408 15.7206 20.6893 15.4393C20.9706 15.158 21.3522 15 21.75 15V12C21.3522 12 20.9706 11.842 20.6893 11.5607C20.408 11.2794 20.25 10.8978 20.25 10.5H12.75ZM6.75 12H8.25V19.5H20.25V21H6.75V12Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/value"
							? classes.textSelected
							: classes.text
					}
					primary="Value"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/brand"}
				component={(props) => <Link {...props} to={"/brand"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/brand" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 11.25C21.435 11.25 21.7875 11.4 22.065 11.6925C22.35 12 22.5 12.3375 22.5 12.75V21C22.5 21.4125 22.35 21.75 22.065 22.0575C21.7875 22.35 21.435 22.5 21 22.5H9C8.565 22.5 8.2125 22.35 7.935 22.0575C7.65 21.75 7.5 21.4125 7.5 21V12.75C7.5 12.3375 7.65 12 7.935 11.6925C8.2125 11.4 8.565 11.25 9 11.25H12V9.75C12 9.315 12.15 8.9625 12.435 8.685C12.7125 8.4 13.065 8.25 13.5 8.25H16.5C16.935 8.25 17.2875 8.4 17.565 8.685C17.85 8.9625 18 9.315 18 9.75V11.25H21ZM9 12.75V21H21V12.75H9ZM16.5 11.25V9.75H13.5V11.25H16.5Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 11.25C21.435 11.25 21.7875 11.4 22.065 11.6925C22.35 12 22.5 12.3375 22.5 12.75V21C22.5 21.4125 22.35 21.75 22.065 22.0575C21.7875 22.35 21.435 22.5 21 22.5H9C8.565 22.5 8.2125 22.35 7.935 22.0575C7.65 21.75 7.5 21.4125 7.5 21V12.75C7.5 12.3375 7.65 12 7.935 11.6925C8.2125 11.4 8.565 11.25 9 11.25H12V9.75C12 9.315 12.15 8.9625 12.435 8.685C12.7125 8.4 13.065 8.25 13.5 8.25H16.5C16.935 8.25 17.2875 8.4 17.565 8.685C17.85 8.9625 18 9.315 18 9.75V11.25H21ZM9 12.75V21H21V12.75H9ZM16.5 11.25V9.75H13.5V11.25H16.5Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/brand"
							? classes.textSelected
							: classes.text
					}
					primary="Brand"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/advertType"}
				component={(props) => <Link {...props} to={"/advertType"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/advertType" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 14.25H9V12H21V14.25ZM21 17.25H15.75V15.75H21V17.25ZM21 20.25H15.75V18.75H21V20.25ZM14.25 20.25H9V15.75H14.25V20.25ZM21.2475 9.5025L20.0025 8.25L18.75 9.5025L17.4975 8.25L16.2525 9.5025L15 8.25L13.7475 9.5025L12.5025 8.25L11.25 9.5025L9.9975 8.25L8.7525 9.5025L7.5 8.25V20.25C7.5 20.6478 7.65804 21.0294 7.93934 21.3107C8.22064 21.592 8.60218 21.75 9 21.75H21C21.3978 21.75 21.7794 21.592 22.0607 21.3107C22.342 21.0294 22.5 20.6478 22.5 20.25V8.25L21.2475 9.5025Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 14.25H9V12H21V14.25ZM21 17.25H15.75V15.75H21V17.25ZM21 20.25H15.75V18.75H21V20.25ZM14.25 20.25H9V15.75H14.25V20.25ZM21.2475 9.5025L20.0025 8.25L18.75 9.5025L17.4975 8.25L16.2525 9.5025L15 8.25L13.7475 9.5025L12.5025 8.25L11.25 9.5025L9.9975 8.25L8.7525 9.5025L7.5 8.25V20.25C7.5 20.6478 7.65804 21.0294 7.93934 21.3107C8.22064 21.592 8.60218 21.75 9 21.75H21C21.3978 21.75 21.7794 21.592 22.0607 21.3107C22.342 21.0294 22.5 20.6478 22.5 20.25V8.25L21.2475 9.5025Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/advertType"
							? classes.textSelected
							: classes.text
					}
					primary="Advert type"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/data"}
				component={(props) => <Link {...props} to={"/data"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/data" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 8.25C11.685 8.25 9 9.5925 9 11.25C9 12.9075 11.685 14.25 15 14.25C18.315 14.25 21 12.9075 21 11.25C21 9.5925 18.315 8.25 15 8.25ZM9 12.75V15C9 16.6575 11.685 18 15 18C18.315 18 21 16.6575 21 15V12.75C21 14.4075 18.315 15.75 15 15.75C11.685 15.75 9 14.4075 9 12.75ZM9 16.5V18.75C9 20.4075 11.685 21.75 15 21.75C18.315 21.75 21 20.4075 21 18.75V16.5C21 18.1575 18.315 19.5 15 19.5C11.685 19.5 9 18.1575 9 16.5Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 8.25C11.685 8.25 9 9.5925 9 11.25C9 12.9075 11.685 14.25 15 14.25C18.315 14.25 21 12.9075 21 11.25C21 9.5925 18.315 8.25 15 8.25ZM9 12.75V15C9 16.6575 11.685 18 15 18C18.315 18 21 16.6575 21 15V12.75C21 14.4075 18.315 15.75 15 15.75C11.685 15.75 9 14.4075 9 12.75ZM9 16.5V18.75C9 20.4075 11.685 21.75 15 21.75C18.315 21.75 21 20.4075 21 18.75V16.5C21 18.1575 18.315 19.5 15 19.5C11.685 19.5 9 18.1575 9 16.5Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/data"
							? classes.textSelected
							: classes.text
					}
					primary="Data"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/sandbox"}
				component={(props) => <Link {...props} to={"/sandbox"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/sandbox" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M20.25 17.25H17.25C17.25 17.8467 17.0129 18.419 16.591 18.841C16.169 19.2629 15.5967 19.5 15 19.5C14.4033 19.5 13.831 19.2629 13.409 18.841C12.9871 18.419 12.75 17.8467 12.75 17.25H9.75V9.75H20.25V17.25ZM20.25 8.25H9.75C8.9175 8.25 8.25 8.925 8.25 9.75V20.25C8.25 20.6478 8.40804 21.0294 8.68934 21.3107C8.97064 21.592 9.35218 21.75 9.75 21.75H20.25C20.6478 21.75 21.0294 21.592 21.3107 21.3107C21.592 21.0294 21.75 20.6478 21.75 20.25V9.75C21.75 9.35218 21.592 8.97064 21.3107 8.68934C21.0294 8.40804 20.6478 8.25 20.25 8.25Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M20.25 17.25H17.25C17.25 17.8467 17.0129 18.419 16.591 18.841C16.169 19.2629 15.5967 19.5 15 19.5C14.4033 19.5 13.831 19.2629 13.409 18.841C12.9871 18.419 12.75 17.8467 12.75 17.25H9.75V9.75H20.25V17.25ZM20.25 8.25H9.75C8.9175 8.25 8.25 8.925 8.25 9.75V20.25C8.25 20.6478 8.40804 21.0294 8.68934 21.3107C8.97064 21.592 9.35218 21.75 9.75 21.75H20.25C20.6478 21.75 21.0294 21.592 21.3107 21.3107C21.592 21.0294 21.75 20.6478 21.75 20.25V9.75C21.75 9.35218 21.592 8.97064 21.3107 8.68934C21.0294 8.40804 20.6478 8.25 20.25 8.25Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/sandbox"
							? classes.textSelected
							: classes.text
					}
					primary="Sandbox"
				/>
				<div
					style={{
						position: "absolute",
						left: 150,
						backgroundColor: "#991700",
						color: "#fff",
						fontSize: 12,
						fontFamily: "Poppins",
						width: 20,
						height: 20,
						borderRadius: 10,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{sandboxCounter}
				</div>
			</ListItem>

			<ListItem
				button
				selected={props.activeRoute === "/sentiment"}
				component={(props) => <Link {...props} to={"/sentiment"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/sentiment" ? (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 15C21 13.4087 20.3679 11.8826 19.2426 10.7574C18.1174 9.63214 16.5913 9 15 9C13.4087 9 11.8826 9.63214 10.7574 10.7574C9.63214 11.8826 9 13.4087 9 15C9 16.5913 9.63214 18.1174 10.7574 19.2426C11.8826 20.3679 13.4087 21 15 21C16.5913 21 18.1174 20.3679 19.2426 19.2426C20.3679 18.1174 21 16.5913 21 15ZM22.5 15C22.5 16.9891 21.7098 18.8968 20.3033 20.3033C18.8968 21.7098 16.9891 22.5 15 22.5C14.0151 22.5 13.0398 22.306 12.1299 21.9291C11.2199 21.5522 10.3931 20.9997 9.6967 20.3033C8.29018 18.8968 7.5 16.9891 7.5 15C7.5 13.0109 8.29018 11.1032 9.6967 9.6967C11.1032 8.29018 13.0109 7.5 15 7.5C15.9849 7.5 16.9602 7.69399 17.8701 8.0709C18.7801 8.44781 19.6069 9.00026 20.3033 9.6967C20.9997 10.3931 21.5522 11.2199 21.9291 12.1299C22.306 13.0398 22.5 14.0151 22.5 15V15ZM13.5 13.125C13.5 13.725 12.975 14.25 12.375 14.25C11.775 14.25 11.25 13.725 11.25 13.125C11.25 12.525 11.775 12 12.375 12C12.975 12 13.5 12.525 13.5 13.125ZM18.75 13.125C18.75 13.725 18.225 14.25 17.625 14.25C17.025 14.25 16.5 13.725 16.5 13.125C16.5 12.525 17.025 12 17.625 12C18.225 12 18.75 12.525 18.75 13.125ZM15 18.9225C13.6875 18.9225 12.5325 18.375 11.8575 17.565L12.9225 16.5C13.26 17.04 14.0625 17.4225 15 17.4225C15.9375 17.4225 16.74 17.04 17.0775 16.5L18.1425 17.565C17.4675 18.375 16.3125 18.9225 15 18.9225Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="30"
							height="30"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21 15C21 13.4087 20.3679 11.8826 19.2426 10.7574C18.1174 9.63214 16.5913 9 15 9C13.4087 9 11.8826 9.63214 10.7574 10.7574C9.63214 11.8826 9 13.4087 9 15C9 16.5913 9.63214 18.1174 10.7574 19.2426C11.8826 20.3679 13.4087 21 15 21C16.5913 21 18.1174 20.3679 19.2426 19.2426C20.3679 18.1174 21 16.5913 21 15ZM22.5 15C22.5 16.9891 21.7098 18.8968 20.3033 20.3033C18.8968 21.7098 16.9891 22.5 15 22.5C14.0151 22.5 13.0398 22.306 12.1299 21.9291C11.2199 21.5522 10.3931 20.9997 9.6967 20.3033C8.29018 18.8968 7.5 16.9891 7.5 15C7.5 13.0109 8.29018 11.1032 9.6967 9.6967C11.1032 8.29018 13.0109 7.5 15 7.5C15.9849 7.5 16.9602 7.69399 17.8701 8.0709C18.7801 8.44781 19.6069 9.00026 20.3033 9.6967C20.9997 10.3931 21.5522 11.2199 21.9291 12.1299C22.306 13.0398 22.5 14.0151 22.5 15V15ZM13.5 13.125C13.5 13.725 12.975 14.25 12.375 14.25C11.775 14.25 11.25 13.725 11.25 13.125C11.25 12.525 11.775 12 12.375 12C12.975 12 13.5 12.525 13.5 13.125ZM18.75 13.125C18.75 13.725 18.225 14.25 17.625 14.25C17.025 14.25 16.5 13.725 16.5 13.125C16.5 12.525 17.025 12 17.625 12C18.225 12 18.75 12.525 18.75 13.125ZM15 18.9225C13.6875 18.9225 12.5325 18.375 11.8575 17.565L12.9225 16.5C13.26 17.04 14.0625 17.4225 15 17.4225C15.9375 17.4225 16.74 17.04 17.0775 16.5L18.1425 17.565C17.4675 18.375 16.3125 18.9225 15 18.9225Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/sentiment"
							? classes.textSelected
							: classes.text
					}
					primary="Sentiment"
				/>
			</ListItem>
			<ListItem
				button
				selected={props.activeRoute === "/topAuthors"}
				component={(props) => <Link {...props} to={"/topAuthors"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/topAuthors" ? (
						<svg
							width="14"
							height="17"
							viewBox="0 0 14 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 7 }}
						>
							<path
								d="M11.5 11.25H2.5V10.575C2.5 9.075 5.5 8.25 7 8.25C8.5 8.25 11.5 9.075 11.5 10.575V11.25ZM7 3.225C8.125 3.225 9.025 4.125 9.025 5.25C9.025 6.375 8.125 7.275 7 7.275C5.875 7.275 4.975 6.375 4.975 5.25C4.975 4.125 5.875 3.225 7 3.225ZM12.25 0.75H1.75C0.9175 0.75 0.25 1.4175 0.25 2.25V12.75C0.25 13.1478 0.408035 13.5294 0.68934 13.8107C0.970644 14.092 1.35218 14.25 1.75 14.25H4.75L7 16.5L9.25 14.25H12.25C12.6478 14.25 13.0294 14.092 13.3107 13.8107C13.592 13.5294 13.75 13.1478 13.75 12.75V2.25C13.75 1.4175 13.075 0.75 12.25 0.75Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="14"
							height="17"
							viewBox="0 0 14 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 7 }}
						>
							<path
								d="M11.5 11.25H2.5V10.575C2.5 9.075 5.5 8.25 7 8.25C8.5 8.25 11.5 9.075 11.5 10.575V11.25ZM7 3.225C8.125 3.225 9.025 4.125 9.025 5.25C9.025 6.375 8.125 7.275 7 7.275C5.875 7.275 4.975 6.375 4.975 5.25C4.975 4.125 5.875 3.225 7 3.225ZM12.25 0.75H1.75C0.9175 0.75 0.25 1.4175 0.25 2.25V12.75C0.25 13.1478 0.408035 13.5294 0.68934 13.8107C0.970644 14.092 1.35218 14.25 1.75 14.25H4.75L7 16.5L9.25 14.25H12.25C12.6478 14.25 13.0294 14.092 13.3107 13.8107C13.592 13.5294 13.75 13.1478 13.75 12.75V2.25C13.75 1.4175 13.075 0.75 12.25 0.75Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/topAuthors"
							? classes.textSelected
							: classes.text
					}
					primary="Top authors"
				/>
			</ListItem>
			{props.user?.book ? (
				<ListItem
					button
					selected={props.activeRoute === "/bookBuilder"}
					component={(props) => (
						<Link {...props} to={"/bookBuilder"} />
					)}
					classes={{ selected: classes.selected }}
				>
					<ListItemIcon>
						{props.activeRoute === "/bookBuilder" ? (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 8.25C11.685 8.25 9 9.5925 9 11.25C9 12.9075 11.685 14.25 15 14.25C18.315 14.25 21 12.9075 21 11.25C21 9.5925 18.315 8.25 15 8.25ZM9 12.75V15C9 16.6575 11.685 18 15 18C18.315 18 21 16.6575 21 15V12.75C21 14.4075 18.315 15.75 15 15.75C11.685 15.75 9 14.4075 9 12.75ZM9 16.5V18.75C9 20.4075 11.685 21.75 15 21.75C18.315 21.75 21 20.4075 21 18.75V16.5C21 18.1575 18.315 19.5 15 19.5C11.685 19.5 9 18.1575 9 16.5Z"
									fill="#3966D0"
								/>
							</svg>
						) : (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 8.25C11.685 8.25 9 9.5925 9 11.25C9 12.9075 11.685 14.25 15 14.25C18.315 14.25 21 12.9075 21 11.25C21 9.5925 18.315 8.25 15 8.25ZM9 12.75V15C9 16.6575 11.685 18 15 18C18.315 18 21 16.6575 21 15V12.75C21 14.4075 18.315 15.75 15 15.75C11.685 15.75 9 14.4075 9 12.75ZM9 16.5V18.75C9 20.4075 11.685 21.75 15 21.75C18.315 21.75 21 20.4075 21 18.75V16.5C21 18.1575 18.315 19.5 15 19.5C11.685 19.5 9 18.1575 9 16.5Z"
									fill="#D0D1D2"
								/>
							</svg>
						)}
					</ListItemIcon>
					<ListItemText
						className={
							props.activeRoute === "/bookBuilder"
								? classes.textSelected
								: classes.text
						}
						primary="Book Builder"
					/>
					<div
						style={{
							position: "absolute",
							left: 175,
							backgroundColor: "#991700",
							color: "#fff",
							fontSize: 12,
							fontFamily: "Poppins",
							width: 20,
							height: 20,
							borderRadius: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{bookbuilderCounter}
					</div>
				</ListItem>
			) : null}
			{/* { props.user?.book ? (
				<ListItem
					button
					selected={props.activeRoute === "/books"}
					component={(props) => <Link {...props} to={"/books"} />}
					classes={{ selected: classes.selected }}
				>
					<ListItemIcon>
						{props.activeRoute === "/books" ? (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 8.25C11.685 8.25 9 9.5925 9 11.25C9 12.9075 11.685 14.25 15 14.25C18.315 14.25 21 12.9075 21 11.25C21 9.5925 18.315 8.25 15 8.25ZM9 12.75V15C9 16.6575 11.685 18 15 18C18.315 18 21 16.6575 21 15V12.75C21 14.4075 18.315 15.75 15 15.75C11.685 15.75 9 14.4075 9 12.75ZM9 16.5V18.75C9 20.4075 11.685 21.75 15 21.75C18.315 21.75 21 20.4075 21 18.75V16.5C21 18.1575 18.315 19.5 15 19.5C11.685 19.5 9 18.1575 9 16.5Z"
									fill="#3966D0"
								/>
							</svg>
						) : (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 8.25C11.685 8.25 9 9.5925 9 11.25C9 12.9075 11.685 14.25 15 14.25C18.315 14.25 21 12.9075 21 11.25C21 9.5925 18.315 8.25 15 8.25ZM9 12.75V15C9 16.6575 11.685 18 15 18C18.315 18 21 16.6575 21 15V12.75C21 14.4075 18.315 15.75 15 15.75C11.685 15.75 9 14.4075 9 12.75ZM9 16.5V18.75C9 20.4075 11.685 21.75 15 21.75C18.315 21.75 21 20.4075 21 18.75V16.5C21 18.1575 18.315 19.5 15 19.5C11.685 19.5 9 18.1575 9 16.5Z"
									fill="#D0D1D2"
								/>
							</svg>
						)}
					</ListItemIcon>
					<ListItemText
						className={
							props.activeRoute === "/books"
								? classes.textSelected
								: classes.text
						}
						primary="Books"
					/>
				</ListItem>
			) : null}
			<ListItem
				button
				selected={props.activeRoute === "/faq"}
				component={(props) => <Link {...props} to={"/faq"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/faq" ? (
						<svg
							width="14"
							height="17"
							viewBox="0 0 14 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 7 }}
						>
							<path
								d="M11.5 11.25H2.5V10.575C2.5 9.075 5.5 8.25 7 8.25C8.5 8.25 11.5 9.075 11.5 10.575V11.25ZM7 3.225C8.125 3.225 9.025 4.125 9.025 5.25C9.025 6.375 8.125 7.275 7 7.275C5.875 7.275 4.975 6.375 4.975 5.25C4.975 4.125 5.875 3.225 7 3.225ZM12.25 0.75H1.75C0.9175 0.75 0.25 1.4175 0.25 2.25V12.75C0.25 13.1478 0.408035 13.5294 0.68934 13.8107C0.970644 14.092 1.35218 14.25 1.75 14.25H4.75L7 16.5L9.25 14.25H12.25C12.6478 14.25 13.0294 14.092 13.3107 13.8107C13.592 13.5294 13.75 13.1478 13.75 12.75V2.25C13.75 1.4175 13.075 0.75 12.25 0.75Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="14"
							height="17"
							viewBox="0 0 14 17"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 7 }}
						>
							<path
								d="M11.5 11.25H2.5V10.575C2.5 9.075 5.5 8.25 7 8.25C8.5 8.25 11.5 9.075 11.5 10.575V11.25ZM7 3.225C8.125 3.225 9.025 4.125 9.025 5.25C9.025 6.375 8.125 7.275 7 7.275C5.875 7.275 4.975 6.375 4.975 5.25C4.975 4.125 5.875 3.225 7 3.225ZM12.25 0.75H1.75C0.9175 0.75 0.25 1.4175 0.25 2.25V12.75C0.25 13.1478 0.408035 13.5294 0.68934 13.8107C0.970644 14.092 1.35218 14.25 1.75 14.25H4.75L7 16.5L9.25 14.25H12.25C12.6478 14.25 13.0294 14.092 13.3107 13.8107C13.592 13.5294 13.75 13.1478 13.75 12.75V2.25C13.75 1.4175 13.075 0.75 12.25 0.75Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/faq"
							? classes.textSelected
							: classes.text
					}
					primary="Help Center - FAQ"
				/>
			</ListItem> */}
			{/* <ListItem
				button
				selected={props.activeRoute === "/wordCloud"}
				component={(props) => <Link {...props} to={"/wordCloud"} />}
				classes={{ selected: classes.selected }}
			>
				<ListItemIcon>
					{props.activeRoute === "/wordcloud" ? (
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 5 }}
						>
							<path
								d="M15.6146 8.78052C15.0951 6.1451 12.7805 4.16663 9.99998 4.16663C7.79234 4.16663 5.87498 5.4194 4.92012 7.25274C2.62081 7.49718 0.833313 9.4451 0.833313 11.8055C0.833313 14.334 2.88817 16.3888 5.41665 16.3888H15.3472C17.4555 16.3888 19.1666 14.6777 19.1666 12.5694C19.1666 10.5527 17.6007 8.91802 15.6146 8.78052ZM15.3472 14.8611H5.41665C3.72845 14.8611 2.36109 13.4937 2.36109 11.8055C2.36109 10.1173 3.72845 8.74996 5.41665 8.74996H5.95901C6.46317 6.98538 8.07498 5.6944 9.99998 5.6944C12.3222 5.6944 14.2014 7.57357 14.2014 9.89579V10.2777H15.3472C16.6153 10.2777 17.6389 11.3013 17.6389 12.5694C17.6389 13.8375 16.6153 14.8611 15.3472 14.8611Z"
								fill="#3966D0"
							/>
						</svg>
					) : (
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ marginLeft: 5 }}
						>
							<path
								d="M15.6146 8.78052C15.0951 6.1451 12.7805 4.16663 9.99998 4.16663C7.79234 4.16663 5.87498 5.4194 4.92012 7.25274C2.62081 7.49718 0.833313 9.4451 0.833313 11.8055C0.833313 14.334 2.88817 16.3888 5.41665 16.3888H15.3472C17.4555 16.3888 19.1666 14.6777 19.1666 12.5694C19.1666 10.5527 17.6007 8.91802 15.6146 8.78052ZM15.3472 14.8611H5.41665C3.72845 14.8611 2.36109 13.4937 2.36109 11.8055C2.36109 10.1173 3.72845 8.74996 5.41665 8.74996H5.95901C6.46317 6.98538 8.07498 5.6944 9.99998 5.6944C12.3222 5.6944 14.2014 7.57357 14.2014 9.89579V10.2777H15.3472C16.6153 10.2777 17.6389 11.3013 17.6389 12.5694C17.6389 13.8375 16.6153 14.8611 15.3472 14.8611Z"
								fill="#D0D1D2"
							/>
						</svg>
					)}
				</ListItemIcon>
				<ListItemText
					className={
						props.activeRoute === "/wordCloud"
							? classes.textSelected
							: classes.text
					}
					primary="Word cloud"
				/>
			</ListItem> */}
			{/* {props.user?.role === "Admin" && props.user?.internal ? (
				<ListItem
					button
					selected={props.activeRoute === "/userManagement"}
					component={(props) => (
						<Link {...props} to={"/userManagement"} />
					)}
					classes={{ selected: classes.selected }}
				>
					<ListItemIcon>
						{props.activeRoute === "/userManagement" ? (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.25 17.25H17.25C17.25 17.8467 17.0129 18.419 16.591 18.841C16.169 19.2629 15.5967 19.5 15 19.5C14.4033 19.5 13.831 19.2629 13.409 18.841C12.9871 18.419 12.75 17.8467 12.75 17.25H9.75V9.75H20.25V17.25ZM20.25 8.25H9.75C8.9175 8.25 8.25 8.925 8.25 9.75V20.25C8.25 20.6478 8.40804 21.0294 8.68934 21.3107C8.97064 21.592 9.35218 21.75 9.75 21.75H20.25C20.6478 21.75 21.0294 21.592 21.3107 21.3107C21.592 21.0294 21.75 20.6478 21.75 20.25V9.75C21.75 9.35218 21.592 8.97064 21.3107 8.68934C21.0294 8.40804 20.6478 8.25 20.25 8.25Z"
									fill="#3966D0"
								/>
							</svg>
						) : (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.25 17.25H17.25C17.25 17.8467 17.0129 18.419 16.591 18.841C16.169 19.2629 15.5967 19.5 15 19.5C14.4033 19.5 13.831 19.2629 13.409 18.841C12.9871 18.419 12.75 17.8467 12.75 17.25H9.75V9.75H20.25V17.25ZM20.25 8.25H9.75C8.9175 8.25 8.25 8.925 8.25 9.75V20.25C8.25 20.6478 8.40804 21.0294 8.68934 21.3107C8.97064 21.592 9.35218 21.75 9.75 21.75H20.25C20.6478 21.75 21.0294 21.592 21.3107 21.3107C21.592 21.0294 21.75 20.6478 21.75 20.25V9.75C21.75 9.35218 21.592 8.97064 21.3107 8.68934C21.0294 8.40804 20.6478 8.25 20.25 8.25Z"
									fill="#D0D1D2"
								/>
							</svg>
						)}
					</ListItemIcon>
					<ListItemText
						className={
							props.activeRoute === "/userManagement"
								? classes.textSelected
								: classes.text
						}
						primary="User Management"
					/>
				</ListItem>
			) : null}
			{props.user?.role === "Admin" && props.user?.internal ? (
				<ListItem
					button
					selected={props.activeRoute === "/clientManagement"}
					component={(props) => (
						<Link {...props} to={"/clientManagement"} />
					)}
					classes={{ selected: classes.selected }}
				>
					<ListItemIcon>
						{props.activeRoute === "/clientManagement" ? (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.25 17.25H17.25C17.25 17.8467 17.0129 18.419 16.591 18.841C16.169 19.2629 15.5967 19.5 15 19.5C14.4033 19.5 13.831 19.2629 13.409 18.841C12.9871 18.419 12.75 17.8467 12.75 17.25H9.75V9.75H20.25V17.25ZM20.25 8.25H9.75C8.9175 8.25 8.25 8.925 8.25 9.75V20.25C8.25 20.6478 8.40804 21.0294 8.68934 21.3107C8.97064 21.592 9.35218 21.75 9.75 21.75H20.25C20.6478 21.75 21.0294 21.592 21.3107 21.3107C21.592 21.0294 21.75 20.6478 21.75 20.25V9.75C21.75 9.35218 21.592 8.97064 21.3107 8.68934C21.0294 8.40804 20.6478 8.25 20.25 8.25Z"
									fill="#3966D0"
								/>
							</svg>
						) : (
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.25 17.25H17.25C17.25 17.8467 17.0129 18.419 16.591 18.841C16.169 19.2629 15.5967 19.5 15 19.5C14.4033 19.5 13.831 19.2629 13.409 18.841C12.9871 18.419 12.75 17.8467 12.75 17.25H9.75V9.75H20.25V17.25ZM20.25 8.25H9.75C8.9175 8.25 8.25 8.925 8.25 9.75V20.25C8.25 20.6478 8.40804 21.0294 8.68934 21.3107C8.97064 21.592 9.35218 21.75 9.75 21.75H20.25C20.6478 21.75 21.0294 21.592 21.3107 21.3107C21.592 21.0294 21.75 20.6478 21.75 20.25V9.75C21.75 9.35218 21.592 8.97064 21.3107 8.68934C21.0294 8.40804 20.6478 8.25 20.25 8.25Z"
									fill="#D0D1D2"
								/>
							</svg>
						)}
					</ListItemIcon>
					<ListItemText
						className={
							props.activeRoute === "/clientManagement"
								? classes.textSelected
								: classes.text
						}
						primary="Client Management"
					/>
				</ListItem>
			) : null} */}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	selected: {
		backgroundColor: "#F3F5F9!important",
		borderRadius: 4,
	},
	text: {
		fontSize: 14,
		fontFamily: "Poppins",
		fontWeight: 500,
		color: "#263238",
	},
	textSelected: {
		fontSize: 14,
		fontFamily: "Poppins",
		fontWeight: 700,
		color: "#3966D0",
	},
}));
