import React from "react";
import clsx from "clsx";
import {
	makeStyles,
	Collapse,
	Box,
	CssBaseline,
	AppBar,
	Toolbar,
	IconButton,
	Grid,
	Typography,
	FormControl,
	FormControlLabel,
	List,
	Checkbox,
	Button,
	Drawer,
	MenuItem,
	Select,
	Tooltip,
	withStyles,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import emitter from "tiny-emitter/instance";
import { Link } from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";

import MainListItems from "./listItems";
import CalendarModal from "./screens/Dashboard/components/CalendarModal";
import FormFilters from "./components/FormFilters";
import LegendComponent from "./components/LegendComponent";
import api from "./api";

const drawerWidth = 270;

export default function Layout(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const [activeRoute, setActiveRoute] = React.useState("/");
	const [periodFilter, setPeriodFilter] = React.useState("this month"); // eslint-disable-line
	const [showFilters, setShowFilters] = React.useState(false);
	const [editorial, setEditorial] = React.useState(
		JSON.parse(sessionStorage.getItem("editorialChecked"))
	);
	const [advertising, setAdvertising] = React.useState(
		JSON.parse(sessionStorage.getItem("advertisingChecked"))
	);
	const [selectedCompany, setSelectedCompany] = React.useState("");
	const [period, setPeriod] = React.useState(
		JSON.parse(sessionStorage.getItem("dateRange")).currentDataDateFrom +
			" - " +
			JSON.parse(sessionStorage.getItem("dateRange")).currentDataDateTo
	);

	const refCalendar = React.createRef();
	let location = useLocation();

	React.useEffect(() => {
		emitter.on("refreshDateRange", (date) => {
			const from = new Date(date.startDate).toISOString().slice(0, 10);
			const to = new Date(date.endDate).toISOString().slice(0, 10);
			setPeriodFilter(from + " - " + to);
		});

		return () => emitter.off("refreshDateRange");
	}, [props.data]); //eslint-disable-line

	React.useEffect(() => {
		setActiveRoute(location.pathname);
	}, [location]);

	React.useEffect(() => {
		// console.log("PROPS LAYOUT: ", props);
		const company = JSON.parse(sessionStorage.getItem("selectedClient"));
		if (!company) sessionStorage.setItem("selectedCompany", 0);
		else {
			if (props.data.clients)
				setSelectedCompany(
					props.data.clients
						.map((e) => e.client.clientName)
						.indexOf(company.clientName)
				);
		}

		setPeriod(
			JSON.parse(sessionStorage.getItem("dateRange")).currentDataDateFrom +
				" - " +
				JSON.parse(sessionStorage.getItem("dateRange")).currentDataDateTo
		);
	}, [props.data]);

	const toggleEditorial = (checked) => {
		setEditorial(checked);
		sessionStorage.setItem("editorialChecked", checked);
		const values = [];
		if (checked) values.push("EDITORIAL");
		if (advertising) values.push("ADVERTISING");

		if (values.length === 0) emitter.emit("filterChange");
		else {
			const ids = [];
			values.forEach((item) => {
				const index = props.data.types.map((e) => e).indexOf(item);
				ids.push(index);
			});

			const storageFilters = JSON.parse(sessionStorage.getItem("filters"));
			const filters = {
				...storageFilters,
				type: ids,
			};
			sessionStorage.setItem("filters", JSON.stringify(filters));

			emitter.emit("filter", [
				{ field: "mediaType", values: storageFilters.mediaType },
				{
					field: "brand",
					values: storageFilters.brand.map(
						(e) => props.data.brands[e]
					),
				},
				{
					field: "mediaSource",
					values: storageFilters.mediaSource.map(
						(e) => props.data.mediaSources[e].itemName
					),
				},
				{
					field: "category",
					values: storageFilters.category.map(
						(e) => props.data.categories[e]
					),
				},
				{
					field: "subCategory",
					values: storageFilters.subCategory.map(
						(e) => props.data.subcategories[e]
					),
				},
				{ field: "type", values: values },
				{
					field: "sentiment",
					values: storageFilters.sentiment.map(
						(e) => props.data.sentimet[e].itemName
					),
				},
				{
					field: "author",
					values: storageFilters.author.map(
						(e) => props.data.authors[e].itemName
					),
				},
				{
					field: "topic",
					values: storageFilters.topic.map(
						(e) => props.data.topics[e]
					),
				},
				{
					field: "advertType",
					values: storageFilters.advertType.map(
						(e) => props.data.advertTypes[e].itemName
					),
				},
				{
					field: "language",
					values: storageFilters.language.map(
						(e) => props.data.languages[e].itemName
					),
				},
				{
					field: "country",
					values: storageFilters.country.map(
						(e) => props.data.countries[e]
					),
				},
				{
					field: "region",
					values: storageFilters.location.map(
						(e) => props.data.locations[e].itemName
					),
				},
				{ field: "description", values: [storageFilters.search] },
			]);
		}
	};

	const toggleAdvertising = (checked) => {
		setAdvertising(checked);
		sessionStorage.setItem("advertisingChecked", advertising);
		const values = [];
		if (editorial) values.push("EDITORIAL");
		if (checked) values.push("ADVERTISING");

		if (values.length === 0) emitter.emit("filterChange");
		else {
			const ids = [];
			values.forEach((item) => {
				const index = props.data.types.map((e) => e).indexOf(item);
				ids.push(index);
			});

			const storageFilters = JSON.parse(sessionStorage.getItem("filters"));
			const filters = {
				...storageFilters,
				type: ids,
			};
			sessionStorage.setItem("filters", JSON.stringify(filters));

			emitter.emit("filter", [
				{ field: "mediaType", values: storageFilters.mediaType },
				{
					field: "brand",
					values: storageFilters.brand.map(
						(e) => props.data.brands[e].itemName
					),
				},
				{
					field: "mediaSource",
					values: storageFilters.mediaSource.map(
						(e) => props.data.mediaSources[e].itemName
					),
				},
				{
					field: "category",
					values: storageFilters.category.map(
						(e) => props.data.categories[e].itemName
					),
				},
				{
					field: "subCategory",
					values: storageFilters.subCategory.map(
						(e) => props.data.subcategories[e].itemName
					),
				},
				{ field: "type", values: values },
				{
					field: "sentiment",
					values: storageFilters.sentiment.map(
						(e) => props.data.sentimet[e].itemName
					),
				},
				{
					field: "author",
					values: storageFilters.author.map(
						(e) => props.data.authors[e].itemName
					),
				},
				{
					field: "topic",
					values: storageFilters.topic.map(
						(e) => props.data.topics[e].itemName
					),
				},
				{
					field: "advertType",
					values: storageFilters.advertType.map(
						(e) => props.data.advertTypes[e].itemName
					),
				},
				{
					field: "language",
					values: storageFilters.language.map(
						(e) => props.data.languages[e].itemName
					),
				},
				{
					field: "country",
					values: storageFilters.country.map(
						(e) => props.data.countries[e].itemName
					),
				},
				{
					field: "region",
					values: storageFilters.location.map(
						(e) => props.data.locations[e].itemName
					),
				},
				{ field: "description", values: [storageFilters.search] },
			]);
		}
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<CalendarModal ref={refCalendar} />
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, open && classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(
							classes.menuButton,
							open && classes.menuButtonHidden
						)}
					>
						<MenuIcon />
					</IconButton>
					<Grid container direction="row" alignItems="center">
						<Grid item>
							<Typography
								component="h1"
								variant="h6"
								color="inherit"
								noWrap
								className={classes.title}
							>
								{activeRoute === "/wordCloud"
									? "Word Cloud"
									: activeRoute === "/advertType"
									? "Advertising Type"
									: activeRoute === "/faq"
									? "Frequently Asked Questions"
									: activeRoute.replace("/", "")}
							</Typography>
						</Grid>

						{activeRoute === "/userManagement" ||
						activeRoute === "/clientManagement" ||
						activeRoute === "/faq" ? null : (
							<Grid item>
								<FormControl className={classes.periodFilter}>
									<Button
										onClick={() => {
											refCalendar?.current.toggleModal(
												true
											);
										}}
									>
										<Typography
											style={{
												fontSize: 18,
												fontFamily: "Poppins",
												color: "#3966D0",
												textTransform: "capitalize",
												marginRight: 10,
											}}
										>
											{periodFilter}
										</Typography>
										<svg
											width="12"
											height="8"
											viewBox="0 0 12 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												opacity="0.8"
												d="M5.88477 7.16211C6.19531 7.15625 6.44727 7.04492 6.69336 6.79297L11 2.38086C11.1875 2.19336 11.2754 1.97656 11.2754 1.70703C11.2754 1.16797 10.8359 0.722656 10.3027 0.722656C10.0391 0.722656 9.78711 0.833984 9.58203 1.03906L5.89062 4.86523L2.1875 1.03906C1.98828 0.839844 1.73633 0.722656 1.4668 0.722656C0.933594 0.722656 0.494141 1.16797 0.494141 1.70703C0.494141 1.9707 0.587891 2.19336 0.769531 2.38672L5.07617 6.79297C5.33398 7.04492 5.58008 7.16211 5.88477 7.16211Z"
												fill="#3966D0"
											/>
										</svg>
									</Button>
								</FormControl>
							</Grid>
						)}

						{activeRoute === "/overview" ? (
							<>
								<Grid item>
									<Typography
										style={{
											fontSize: 18,
											fontFamily: "Poppins",
											color: "#3966D0",
											marginRight: 30,
										}}
									>
										{period}
									</Typography>
								</Grid>

								<FormControlLabel
									className={classes.label}
									style={{ paddingRight: 12 }}
									control={
										<Checkbox
											value="editorial"
											checked={editorial}
											style={{
												color: editorial
													? "#6084D9"
													: "#263238",
												padding: 0,
												marginRight: 3,
											}}
											onChange={() =>
												toggleEditorial(!editorial)
											}
										/>
									}
									label="Editorial"
								/>
								<FormControlLabel
									className={classes.label}
									control={
										<Checkbox
											value="advertising"
											checked={advertising}
											style={{
												color: advertising
													? "#6084D9"
													: "#263238",
												padding: 0,
												marginRight: 3,
											}}
											onChange={() =>
												toggleAdvertising(!advertising)
											}
										/>
									}
									label="Advertising"
								/>
							</>
						) : (
							<>
								{![
									"/sandbox",
									"/bookBuilder",
									"/faq",
									"/userManagement",
									"/clientManagement",
								].includes(activeRoute) && (
									<>
										<Button
											onClick={() =>
												setShowFilters(!showFilters)
											}
										>
											<Box
												display="flex"
												flexDirection="row"
												alignItems="center"
											>
												{showFilters ? (
													<>
														<svg
															width="22"
															height="22"
															viewBox="0 0 22 22"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																opacity="0.8"
																d="M11.2944 21.0557C17.0161 21.0557 21.7534 16.3184 21.7534 10.5967C21.7534 4.88525 17.0059 0.137695 11.2842 0.137695C5.57275 0.137695 0.835449 4.88525 0.835449 10.5967C0.835449 16.3184 5.58301 21.0557 11.2944 21.0557ZM11.2944 19.3125C6.45459 19.3125 2.58887 15.4365 2.58887 10.5967C2.58887 5.76709 6.44434 1.88086 11.2842 1.88086C16.124 1.88086 20 5.76709 20.0103 10.5967C20.0205 15.4365 16.1343 19.3125 11.2944 19.3125ZM5.70605 8.62793H16.8931C17.3442 8.62793 17.6724 8.34082 17.6724 7.91016C17.6724 7.46924 17.3442 7.18213 16.8931 7.18213H5.70605C5.25488 7.18213 4.93701 7.46924 4.93701 7.91016C4.93701 8.34082 5.25488 8.62793 5.70605 8.62793ZM7.23389 12.022H15.3755C15.8267 12.022 16.1445 11.7349 16.1445 11.3042C16.1445 10.8633 15.8267 10.5762 15.3755 10.5762H7.23389C6.77246 10.5762 6.45459 10.8633 6.45459 11.3042C6.45459 11.7349 6.77246 12.022 7.23389 12.022ZM8.81299 15.4263H13.7861C14.2373 15.4263 14.5654 15.1392 14.5654 14.7085C14.5654 14.2676 14.2373 13.9805 13.7861 13.9805H8.81299C8.36182 13.9805 8.04395 14.2676 8.04395 14.7085C8.04395 15.1392 8.36182 15.4263 8.81299 15.4263Z"
																fill="black"
															/>
														</svg>
														<Typography
															style={{
																fontSize: 18,
																fontFamily:
																	"Poppins-Medium",
																color: "#000000",
																textTransform:
																	"capitalize",
																marginLeft: 8,
															}}
														>
															Filter report
														</Typography>
													</>
												) : (
													<>
														<svg
															width="22"
															height="22"
															viewBox="0 0 22 22"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																opacity="0.8"
																d="M11.2944 21.0557C17.0161 21.0557 21.7534 16.3184 21.7534 10.5967C21.7534 4.88525 17.0059 0.137695 11.2842 0.137695C5.57275 0.137695 0.835449 4.88525 0.835449 10.5967C0.835449 16.3184 5.58301 21.0557 11.2944 21.0557ZM11.2944 19.3125C6.45459 19.3125 2.58887 15.4365 2.58887 10.5967C2.58887 5.76709 6.44434 1.88086 11.2842 1.88086C16.124 1.88086 20 5.76709 20.0103 10.5967C20.0205 15.4365 16.1343 19.3125 11.2944 19.3125ZM5.70605 8.62793H16.8931C17.3442 8.62793 17.6724 8.34082 17.6724 7.91016C17.6724 7.46924 17.3442 7.18213 16.8931 7.18213H5.70605C5.25488 7.18213 4.93701 7.46924 4.93701 7.91016C4.93701 8.34082 5.25488 8.62793 5.70605 8.62793ZM7.23389 12.022H15.3755C15.8267 12.022 16.1445 11.7349 16.1445 11.3042C16.1445 10.8633 15.8267 10.5762 15.3755 10.5762H7.23389C6.77246 10.5762 6.45459 10.8633 6.45459 11.3042C6.45459 11.7349 6.77246 12.022 7.23389 12.022ZM8.81299 15.4263H13.7861C14.2373 15.4263 14.5654 15.1392 14.5654 14.7085C14.5654 14.2676 14.2373 13.9805 13.7861 13.9805H8.81299C8.36182 13.9805 8.04395 14.2676 8.04395 14.7085C8.04395 15.1392 8.36182 15.4263 8.81299 15.4263Z"
																fill="#3966D0"
															/>
														</svg>
														<Typography
															style={{
																fontSize: 18,
																fontFamily:
																	"Poppins-Medium",
																color: "#3966D0",
																textTransform:
																	"capitalize",
																marginLeft: 8,
															}}
														>
															Filter report
														</Typography>
													</>
												)}
											</Box>
										</Button>
										<Grid item>
											<Typography
												style={{
													fontSize: 18,
													fontFamily: "Poppins",
													color: "#3966D0",
													marginLeft: 30,
												}}
											>
												{period}
											</Typography>
										</Grid>
									</>
								)}

								{activeRoute === "/data" && (
									<Box
										display="flex"
										flexDirection="row"
										alignItems="center"
										style={{ marginLeft: 10 }}
									>
										<Tooltip
											title="Remove selection from Analytics"
											placement="bottom"
										>
											<IconButton
												color="inherit"
												onClick={() => {
													emitter.emit("sandbox");
												}}
											>
												<svg
													width="23"
													height="20"
													viewBox="0 0 23 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														opacity="0.8"
														d="M19.0923 19.0508C21.2354 19.0508 22.312 17.9946 22.312 15.8721V3.35205C22.312 1.22949 21.2354 0.17334 19.0923 0.17334H10.1816C8.98193 0.17334 7.94629 0.480957 7.10547 1.37305L1.06592 7.64844C0.399414 8.33545 0.0917969 8.95068 0.0917969 9.59668C0.0917969 10.2324 0.38916 10.8579 1.06592 11.5449L7.11572 17.7896C7.9668 18.6714 8.99219 19.0405 10.1919 19.0405L19.0923 19.0508ZM19.0718 17.3999H10.1611C9.38184 17.3999 8.8999 17.2153 8.35645 16.6616L2.34766 10.458C1.99902 10.0991 1.89648 9.85303 1.89648 9.59668C1.89648 9.33008 2.00928 9.08398 2.34766 8.7251L8.34619 2.50098C8.87939 1.95752 9.38184 1.82422 10.1509 1.82422H19.0718C20.0972 1.82422 20.6611 2.36768 20.6611 3.43408V15.79C20.6611 16.8564 20.0972 17.3999 19.0718 17.3999ZM16.498 13.811C16.939 13.811 17.2979 13.4624 17.2979 13.0317C17.2979 12.8164 17.2056 12.6318 17.0518 12.478L14.1807 9.59668L17.0518 6.72559C17.2056 6.57178 17.2979 6.37695 17.2979 6.17188C17.2979 5.73096 16.9287 5.36182 16.498 5.36182C16.293 5.36182 16.0981 5.4541 15.9443 5.60791L13.0732 8.48926L10.1919 5.60791C10.0381 5.4541 9.85352 5.36182 9.63818 5.36182C9.20752 5.36182 8.83838 5.73096 8.83838 6.17188C8.83838 6.37695 8.93066 6.57178 9.08447 6.72559L11.9556 9.59668L9.08447 12.478C8.93066 12.6318 8.83838 12.8164 8.83838 13.0317C8.83838 13.4624 9.19727 13.811 9.63818 13.811C9.86377 13.811 10.0381 13.7393 10.1816 13.5957L13.0732 10.6938L15.9546 13.5957C16.0981 13.7393 16.2827 13.811 16.498 13.811Z"
														fill="#3966D0"
													/>
												</svg>
											</IconButton>
										</Tooltip>
										<Tooltip
											title="Restore dataset"
											placement="bottom"
										>
											<IconButton
												color="inherit"
												onClick={() => {
													emitter.emit("revert");
												}}
											>
												<svg
													width="22"
													height="24"
													viewBox="0 0 22 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														opacity="0.8"
														d="M11.2944 23.0557C17.0161 23.0557 21.7534 18.3184 21.7534 12.5967C21.7534 7.55176 18.0723 3.27588 13.2734 2.33252V0.876465C13.2734 0.148438 12.771 -0.0463867 12.2173 0.353516L8.94629 2.64014C8.47461 2.96826 8.46436 3.46045 8.94629 3.79883L12.207 6.0957C12.771 6.50586 13.2734 6.31104 13.2734 5.57275V4.10645C17.1494 4.98828 20 8.44385 20 12.5967C20 17.4365 16.1343 21.3125 11.2944 21.3125C6.45459 21.3125 2.56836 17.4365 2.57861 12.5967C2.58887 9.68457 4.00391 7.11084 6.18799 5.55225C6.6084 5.23438 6.7417 4.75244 6.48535 4.32178C6.229 3.90137 5.66504 3.78857 5.21387 4.13721C2.57861 6.05469 0.835449 9.15137 0.835449 12.5967C0.835449 18.3184 5.58301 23.0557 11.2944 23.0557Z"
														fill="#3966D0"
													/>
												</svg>
											</IconButton>
										</Tooltip>
										<Tooltip
											title="Export to Excel"
											placement="bottom"
										>
											<IconButton
												color="inherit"
												onClick={() => {
													emitter.emit("export");
												}}
											>
												<svg
													width="25"
													height="20"
													viewBox="0 0 25 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														opacity="0.8"
														d="M3.83447 19.0508H21.5737C23.7271 19.0508 24.7935 17.9844 24.7935 15.8721V3.35205C24.7935 1.23975 23.7271 0.17334 21.5737 0.17334H3.83447C1.69141 0.17334 0.614746 1.22949 0.614746 3.35205V15.8721C0.614746 17.9946 1.69141 19.0508 3.83447 19.0508ZM2.26562 3.43408C2.26562 2.36768 2.82959 1.82422 3.85498 1.82422H11.9863V6.04883H2.26562V3.43408ZM13.4321 6.04883V1.82422H21.5532C22.5684 1.82422 23.1426 2.36768 23.1426 3.43408V6.04883H13.4321ZM13.4321 11.7295V7.49463H23.1426V11.7295H13.4321ZM11.9863 7.49463V11.7295H2.26562V7.49463H11.9863ZM21.5532 17.3999H13.4321V13.1753H23.1426V15.79C23.1426 16.8564 22.5684 17.3999 21.5532 17.3999ZM3.85498 17.3999C2.82959 17.3999 2.26562 16.8564 2.26562 15.79V13.1753H11.9863V17.3999H3.85498Z"
														fill="#3966D0"
													/>
												</svg>
											</IconButton>
										</Tooltip>
										{props.user?.book ? (
											<Tooltip
												title="Book"
												placement="bottom"
											>
												<IconButton
													color="inherit"
													onClick={() => {
														emitter.emit(
															"book",
															true
														);
													}}
												>
													<svg
														width="25"
														height="20"
														viewBox="0 0 400 500"
														fill="#6084d8"
														xmlns="http://www.w3.org/2000/svg"
													>
														<g>
															<path d="m69,117.2c49.9-13.6 89.5-13.6 139.4,0l5.2-20.9c-54.1-14.6-97.8-14.6-150.9,0l6.3,20.9z" />
															<path d="m63.8,171.5l5.2,19.8c49.9-13.6 89.5-13.6 139.4,0l6.2-19.8c-54-14.6-97.7-14.6-150.8,0z" />
															<path d="m63.8,245.5l5.2,19.8c49.9-13.6 89.5-13.6 139.4,0l6.2-19.8c-54-14.6-97.7-14.6-150.8,0z" />
															<path d="m62.8,319.5l6.2,19.8c49.9-13.6 89.5-13.6 139.4,0l5.2-19.8c-54-14.6-97.7-14.6-150.8,0z" />
															<path d="m296.9,96.4l6.2,20.9c49.9-13.6 89.5-13.6 139.4,0l5.2-20.9c-54.1-14.6-97.8-14.6-150.8,0z" />
															<path d="m296.9,170.4l5.2,20.9c51-13.6 89.5-13.6 140.4,0l5.2-20.9c-54.1-14.6-97.8-14.6-150.8,0z" />
															<path d="m296.9,244.4l6.2,20.9c49.9-13.6 89.5-13.6 139.4,0l5.2-20.9c-54.1-14.6-97.8-14.6-150.8,0z" />
															<path d="m493.5,38c-48.9-17.7-85.3-25-120.7-25-34.5,0-70,6.9-117,23.7-47.1-16.8-82.6-23.7-117-23.7-35.4,0-71.8,7.3-120.7,25-4.2,1-7.3,5.2-7.3,9.4v371.1c0,9.9 10.4,10.4 13.5,9.4 45.8-16.7 80.1-24 113.4-24s66.6,7.3 113.4,24c3.2,0.8 5.6,0.5 7.4-0.2 11.5-4.1 31.7-10.7 45-14.8 3.1,5 6.8,9.7 11.1,14 27.9,27.9 71.5,30.3 102.1,7.2l64.8,64.9 14.7-14.7-64.7-64.9c2-2.6 3.8-5.2 5.4-8 15.7,4.7 36.4,11.6 50.4,16.5 3.1,1 13.5,1 13.5-9.4v-371.1c0-4.2-3.1-8.4-7.3-9.4zm-248.6,365.9c-42.7-14.6-74.9-20.9-107.2-20.9s-65.5,6.3-107.2,20.9v-349.2c42.7-15.7 75-21.9 107.2-21.9s64.5,6.3 107.2,21.9v349.2zm84.4,8.3c-22.3-22.4-22.3-58.7 0-81.1 22.3-22.4 58.6-22.4 80.9,0 22.3,22.4 22.3,58.7 0,81.1-22.4,22.4-58.6,22.4-80.9-5.68434e-14zm150.7-8.3c-8.7-2.9-24.1-7.6-35.2-11 7.4-26.4 0.8-55.9-19.9-76.6-30.5-30.5-79.9-30.5-110.3,0-21.1,21.1-27.6,51.3-19.5,78-8.7,2.8-21.6,6.9-30.4,9.7v-349c44-17.1 78.2-22.3 109.2-22.3 31.2,0 63.5,6.3 106.1,21.9v349.3z" />
														</g>
													</svg>
												</IconButton>
											</Tooltip>
										) : null}
									</Box>
								)}

								{activeRoute === "/sandbox" && (
									<Box
										display="flex"
										flexDirection="row"
										alignItems="center"
									>
										<Tooltip
											title="Restore dataset"
											placement="bottom"
										>
											<IconButton
												color="inherit"
												onClick={() => {
													emitter.emit("restore");
												}}
											>
												<svg
													width="23"
													height="20"
													viewBox="0 0 23 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														opacity="0.8"
														d="M19.0923 19.0508C21.2354 19.0508 22.312 17.9946 22.312 15.8721V3.35205C22.312 1.22949 21.2354 0.17334 19.0923 0.17334H10.1816C8.98193 0.17334 7.94629 0.480957 7.10547 1.37305L1.06592 7.64844C0.399414 8.33545 0.0917969 8.95068 0.0917969 9.59668C0.0917969 10.2324 0.38916 10.8579 1.06592 11.5449L7.11572 17.7896C7.9668 18.6714 8.99219 19.0405 10.1919 19.0405L19.0923 19.0508ZM19.0718 17.3999H10.1611C9.38184 17.3999 8.8999 17.2153 8.35645 16.6616L2.34766 10.458C1.99902 10.0991 1.89648 9.85303 1.89648 9.59668C1.89648 9.33008 2.00928 9.08398 2.34766 8.7251L8.34619 2.50098C8.87939 1.95752 9.38184 1.82422 10.1509 1.82422H19.0718C20.0972 1.82422 20.6611 2.36768 20.6611 3.43408V15.79C20.6611 16.8564 20.0972 17.3999 19.0718 17.3999ZM16.498 13.811C16.939 13.811 17.2979 13.4624 17.2979 13.0317C17.2979 12.8164 17.2056 12.6318 17.0518 12.478L14.1807 9.59668L17.0518 6.72559C17.2056 6.57178 17.2979 6.37695 17.2979 6.17188C17.2979 5.73096 16.9287 5.36182 16.498 5.36182C16.293 5.36182 16.0981 5.4541 15.9443 5.60791L13.0732 8.48926L10.1919 5.60791C10.0381 5.4541 9.85352 5.36182 9.63818 5.36182C9.20752 5.36182 8.83838 5.73096 8.83838 6.17188C8.83838 6.37695 8.93066 6.57178 9.08447 6.72559L11.9556 9.59668L9.08447 12.478C8.93066 12.6318 8.83838 12.8164 8.83838 13.0317C8.83838 13.4624 9.19727 13.811 9.63818 13.811C9.86377 13.811 10.0381 13.7393 10.1816 13.5957L13.0732 10.6938L15.9546 13.5957C16.0981 13.7393 16.2827 13.811 16.498 13.811Z"
														fill="#3966D0"
													/>
												</svg>
											</IconButton>
										</Tooltip>
										<Tooltip
											title="Export to Excel"
											placement="bottom"
										>
											<IconButton
												color="inherit"
												onClick={() => {
													emitter.emit("export");
												}}
											>
												<svg
													width="25"
													height="20"
													viewBox="0 0 25 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														opacity="0.8"
														d="M3.83447 19.0508H21.5737C23.7271 19.0508 24.7935 17.9844 24.7935 15.8721V3.35205C24.7935 1.23975 23.7271 0.17334 21.5737 0.17334H3.83447C1.69141 0.17334 0.614746 1.22949 0.614746 3.35205V15.8721C0.614746 17.9946 1.69141 19.0508 3.83447 19.0508ZM2.26562 3.43408C2.26562 2.36768 2.82959 1.82422 3.85498 1.82422H11.9863V6.04883H2.26562V3.43408ZM13.4321 6.04883V1.82422H21.5532C22.5684 1.82422 23.1426 2.36768 23.1426 3.43408V6.04883H13.4321ZM13.4321 11.7295V7.49463H23.1426V11.7295H13.4321ZM11.9863 7.49463V11.7295H2.26562V7.49463H11.9863ZM21.5532 17.3999H13.4321V13.1753H23.1426V15.79C23.1426 16.8564 22.5684 17.3999 21.5532 17.3999ZM3.85498 17.3999C2.82959 17.3999 2.26562 16.8564 2.26562 15.79V13.1753H11.9863V17.3999H3.85498Z"
														fill="#3966D0"
													/>
												</svg>
											</IconButton>
										</Tooltip>
									</Box>
								)}

								{activeRoute === "/bookBuilder" && (
									<Box
										display="flex"
										flexDirection="row"
										alignItems="center"
									>
										<Tooltip
											title="Remove book item"
											placement="bottom"
										>
											<IconButton
												color="inherit"
												onClick={() => {
													emitter.emit(
														"removeBook",
														true
													);
												}}
											>
												<svg
													width="23"
													height="20"
													viewBox="0 0 38.763 38.763"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														opacity="0.8"
														d="M35.469,34.514l-4.422-4.854c-0.016-0.017-0.016-0.067,0-0.084l4.422-4.858
														c0.939-1.035,0.863-2.645-0.171-3.587c-0.45-0.409-1.054-0.636-1.698-0.636c-0.406,0-0.795,0.099-1.145,0.266V1.565
														C32.455,0.703,31.753,0,30.889,0H4.203C3.339,0,2.637,0.703,2.637,1.565v35.633c0,0.862,0.702,1.565,1.566,1.565h17.27
														l-0.049-0.037c0.043,0.002,0.084,0.01,0.128,0.01c0.733,0,1.421-0.293,1.89-0.803l4.135-4.545l4.135,4.543
														c0.48,0.528,1.165,0.832,1.879,0.832c0.633,0,1.239-0.236,1.711-0.664C36.332,37.159,36.408,35.551,35.469,34.514z M19.225,37.198
														H4.203V1.565h26.686V22.2l-3.313,3.642l-4.136-4.544c-0.465-0.512-1.152-0.806-1.888-0.806c-0.644,0-1.248,0.227-1.7,0.637
														c-1.035,0.941-1.111,2.553-0.168,3.588l4.418,4.854c0.017,0.019,0.018,0.07,0,0.088l-4.42,4.855
														C18.999,35.266,18.859,36.316,19.225,37.198z M34.438,37.154c-0.474,0.428-1.354,0.388-1.779-0.084l-4.178-4.592
														c-0.236-0.261-0.56-0.405-0.904-0.405c-0.348,0-0.666,0.145-0.905,0.405l-4.177,4.594c-0.229,0.248-0.569,0.393-0.938,0.393
														c-0.324,0-0.623-0.109-0.843-0.311c-0.513-0.467-0.55-1.264-0.084-1.777l4.42-4.857c0.46-0.506,0.461-1.302,0-1.81l-4.42-4.854
														c-0.466-0.514-0.429-1.312,0.084-1.777c0.472-0.43,1.353-0.387,1.779,0.083l4.177,4.591c0.456,0.504,1.352,0.504,1.809,0.001
														l4.178-4.591c0.429-0.471,1.31-0.514,1.779-0.085c0.248,0.228,0.396,0.534,0.409,0.872c0.015,0.336-0.101,0.656-0.325,0.906
														l-4.42,4.857c-0.459,0.505-0.461,1.301,0,1.809l4.42,4.854C34.986,35.891,34.949,36.688,34.438,37.154z"
														fill="#3966D0"
													/>
												</svg>
											</IconButton>
										</Tooltip>
									</Box>
								)}
							</>
						)}

						<Grid item style={{ flex: 1 }}>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<CustomSelect
										disableUnderline
										value={selectedCompany}
										classes={{ root: classes.select }}
										style={{ marginRight: 30 }}
										inputProps={{
											"aria-label": "Without label",
										}}
										onChange={(event) => {
											setSelectedCompany(
												event.target.value
											);
											sessionStorage.setItem(
												"selectedClient",
												JSON.stringify(
													props.data.clients[
														event.target.value
													].client
												)
											);
											sessionStorage.setItem(
												"mediaTypes",
												JSON.stringify([])
											);
											emitter.emit("getData");
										}}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
										}}
										IconComponent={() => (
											<svg
												width="12"
												height="8"
												viewBox="0 0 12 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.41 -7.62939e-08L6 4.59L10.59 -7.62939e-08L12 1.42L6 7.42L0 1.42L1.41 -7.62939e-08Z"
													fill="#909597"
												/>
											</svg>
										)}
									>
										{props.data?.clients &&
											props.data.clients.map(
												(item, index) => {
													return (
														<MenuItem
															key={
																"client" +
																index.toString()
															}
															className={
																classes.menuItem
															}
															style={{
																fontSize: 13,
																fontFamily:
																	"Poppins-Medium",
																color: "#263238",
															}}
															value={index}
														>
															<div
																className={
																	classes.selectItem
																}
															>
																{
																	item.client
																		.clientName
																}
															</div>
														</MenuItem>
													);
												}
											)}
									</CustomSelect>
								</Grid>

								<Grid item>
									<CustomSelect
										disableUnderline
										value={0}
										classes={{ root: classes.select }}
										inputProps={{
											"aria-label": "Without label",
										}}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
										}}
										IconComponent={() => (
											<svg
												width="12"
												height="8"
												viewBox="0 0 12 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.41 -7.62939e-08L6 4.59L10.59 -7.62939e-08L12 1.42L6 7.42L0 1.42L1.41 -7.62939e-08Z"
													fill="#909597"
												/>
											</svg>
										)}
									>
										<MenuItem
											hidden
											className={classes.menuItem}
											value={0}
										>
											<div
												className={classes.selectItem}
												onClick={() => {}}
											>
												{
													api.oauth.getAuth()?.user
														?.firstName
												}{" "}
												{
													api.oauth.getAuth()?.user
														?.lastName
												}
											</div>
										</MenuItem>
										{props.user?.book ? (
											<MenuItem
												button
												selected={
													props.activeRoute ===
													"/books"
												}
												component={(props) => (
													<Link
														{...props}
														to={"/books"}
													/>
												)}
												classes={{
													selected: classes.selected,
												}}
											>
												<ListItemText
													className={
														props.activeRoute ===
														"/books"
															? classes.textSelected
															: classes.text
													}
													primary="Books"
												/>
											</MenuItem>
										) : null}
										<MenuItem
											selected={
												props.activeRoute === "/faq"
											}
											component={(props) => (
												<Link {...props} to={"/faq"} />
											)}
											classes={{
												selected: classes.selected,
											}}
										>
											<ListItemText
												className={
													props.activeRoute === "/faq"
														? classes.textSelected
														: classes.text
												}
												primary="Help Center - FAQ"
											/>
										</MenuItem>
										{props.user?.role === "Admin" &&
										props.user?.internal ? (
											<MenuItem
												selected={
													props.activeRoute ===
													"/userManagement"
												}
												component={(props) => (
													<Link
														{...props}
														to={"/userManagement"}
													/>
												)}
												classes={{
													selected: classes.selected,
												}}
											>
												<ListItemText
													className={
														props.activeRoute ===
														"/userManagement"
															? classes.textSelected
															: classes.text
													}
													primary="User Management"
												/>
											</MenuItem>
										) : null}
										{props.user?.role === "Admin" &&
										props.user?.internal ? (
											<MenuItem
												selected={
													props.activeRoute ===
													"/clientManagement"
												}
												component={(props) => (
													<Link
														{...props}
														to={"/clientManagement"}
													/>
												)}
												classes={{
													selected: classes.selected,
												}}
											>
												<ListItemText
													className={
														props.activeRoute ===
														"/clientManagement"
															? classes.textSelected
															: classes.text
													}
													primary="Client Management"
												/>
											</MenuItem>
										) : null}
										<MenuItem
											className={classes.menuItem}
											value={2}
										>
											<div
												className={classes.selectItem}
												style={{ color: "#D26767" }}
												onClick={() => {
													emitter.emit("logout");
												}}
											>
												Logout
											</div>
										</MenuItem>
									</CustomSelect>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(
						classes.drawerPaper,
						!open && classes.drawerPaperClose
					),
				}}
				className={classes.drawer}
				open={open}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={handleDrawerClose}>
						<svg
							width="14"
							height="12"
							viewBox="0 0 14 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0 0H14V2H0V0ZM0 5H14V7H0V5ZM0 10H14V12H0V10Z"
								fill="#263238"
							/>
						</svg>
					</IconButton>
					<img
						src={require("./assets/img/logo-01.png").default}
						alt="logo"
						style={{ width: 150, marginLeft: 20 }}
					/>
				</div>
				<List>
					<MainListItems
						activeRoute={activeRoute}
						user={props.user}
					/>
				</List>
			</Drawer>

			<main style={{ flex: 1 }}>
				<div className={classes.appBarSpacer} />

				<Collapse in={showFilters}>
					<FormFilters
						data={props.data}
						onClose={() => setShowFilters(false)}
					/>
				</Collapse>

				{![
					"/overview",
					"/wordCloud",
					"/sandbox",
					"/faq",
					"/userManagement",
					"/clientManagement",
					"/books",
					"/bookBuilder",
				].includes(activeRoute) ? (
					<LegendComponent route={location} data={props.data} />
				) : null}
				{props.children}
			</main>
		</div>
	);
}

const CustomSelect = withStyles({
	root: {
		paddingRight: 25,
	},
})(Select);

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24,
	},
	toolbarIcon: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: "#fafafa",
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		// flexGrow: 1,
		fontSize: 24,
		fontFamily: "Poppins",
		color: "#263238",
		marginRight: 35,
		textTransform: "capitalize",
	},
	periodFilter: {
		marginRight: 20,
	},
	select: {
		paddingRight: "10px!important",
	},
	selectItem: {
		fontSize: 13,
		fontFamily: "Poppins-Medium",
		color: "#263238",
		"&:hover": {
			color: "#3966D0",
		},
	},
	menuItem: {
		fontSize: 13,
		fontFamily: "Poppins-Medium",
		color: "#263238",
		"&:hover": {
			backgroundColor: "rgba(96,132,217, 0.14)!important",
		},
	},
	label: {
		fontSize: 14,
		fontFamily: "Poppins",
		color: "#263238",
		fontWeight: 500,
		lineHeight: 20,
	},
	checkbox: {
		padding: 0,
		marginRight: 3,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: "#fff",
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		width: "100%",
		height: "100vh",
		overflow: "auto",
		backgroundColor: "#fafafa",
	},
	container: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	margin: {
		margin: theme.spacing(4),
	},
}));
