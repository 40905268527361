import React from 'react';
import Modal from '@material-ui/core/Modal';
import emitter from 'tiny-emitter/instance';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grow, Backdrop } from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    getTime,
    parseISO
} from 'date-fns';

function getModalStyle() {
    return {
        top: 64,
        left: 270,
        transform: `translate(64,270)`,
    };
}

function CalendarModal(props, ref) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState([
        {
            startDate: getTime(parseISO(JSON.parse(sessionStorage.getItem('dateRange')).currentDataDateFrom)),
            endDate: getTime(parseISO(JSON.parse(sessionStorage.getItem('dateRange')).currentDataDateTo)),
            key: 'selection',
            color: '#3947A5'
        }
    ]);

    React.useEffect(() => {
        // console.log('INITIAL: ', getTime(parseISO(JSON.parse(sessionStorage.getItem('dateRange')).currentDataDateFrom)));
        // console.log('DATA: ', getTime(parseISO(JSON.parse(sessionStorage.getItem('dateRange')).currentDataDateFrom)));
    })

    React.useImperativeHandle(ref, () => ({
        toggleModal: (value) => {
            setOpen(value);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <>
            <div style={modalStyle} className={classes.paper}>
                <DateRangePicker
                    ranges={dateRange}
                    onChange={date => {
                        // console.log('DATE: ', new Date(date.selection.startDate).valueOf());
                        setDateRange([{
                            startDate: new Date(date.selection.startDate).valueOf(),
                            endDate: new Date(date.selection.endDate).valueOf(),
                            key: 'selection',
                            color: '#3947A5'
                        }])
                    }}
                    months={2}
                    direction={'horizontal'}
                    staticRanges={staticRanges}
                />
                <div style={{ width: '100%', height: 72, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 20, boxShadow: '0px -2px 20px -1px rgba(0,0,0,0.1)' }}>
                    <Button
                        onClick={() => { setOpen(false) }}
                        className={classes.cancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => { emitter.emit('dateRange', dateRange); setOpen(false) }}
                        className={classes.apply}>
                        Apply
                    </Button>
                </div>
            </div>
        </>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}>
            <Grow in={open}>
                {body}
            </Grow>
        </Modal>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    },
    cancel: {
        width: 96,
        height: 32,
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderRadius: 5,
        fontSize: 13,
        fontFamily: 'Roboto',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        }
    },
    apply: {
        width: 96,
        height: 32,
        backgroundColor: '#3947A5',
        fontSize: 13,
        fontFamily: 'Roboto',
        color: '#fff',
        textTransform: 'capitalize',
        marginLeft: 20,
        '&:hover': {
            backgroundColor: '#3947A5'
        }
    }
}));

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        // console.log('range: ', definedRange);
        return (
            isSameDay(range.startDate, definedRange.startDate) &&
            isSameDay(range.endDate, definedRange.endDate)
        );
    },
};

function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

const staticRanges = createStaticRanges([
    {
        label: 'Today',
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
        })
    },
    {
        label: 'Yesterday',
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
        })
    },

    {
        label: 'This Week',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        })
    },
    {
        label: 'Last Week',
        range: () => ({
            startDate: defineds.startOfLastWeek,
            endDate: defineds.endOfLastWeek,
        })
    },
    {
        label: 'This Month',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        })
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
        })
    },
]);

export default React.forwardRef(CalendarModal);