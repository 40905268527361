import axios from "axios";
import emitter from "tiny-emitter/instance";

var api = {};
var apiAuth = {};
// var apiUrl = process.env.REACT_APP_APIURL; //sorry Derrick, this doesn't work on https://ornico.touch-media.ro/
var apiUrl = "https://fusion2.ornico.co.za";
// var apiUrl = "http://localhost:3000"

api.url = apiUrl;
api.baseUrl = apiUrl;
api.oauth = {};

apiAuth = {
	logged: false,
	user: null,
	token: {
		token_type: "Bearer",
		access_token: null,
		expires_at: null,
	},
};

api.post = (url, data, responsetype = "") => {
	return api.request("POST", url, data, responsetype);
};

api.put = (url, data) => {
	return api.request("PUT", url, data);
};

api.get = (url, data) => {
	return api.request("GET", url, data);
};
api.delete = (url, data) => {
	return api.request("DELETE", url, data);
};
api.request = (method, url, data, responsetype) => {
	return new Promise((resolve, reject) => {
		method = method.toUpperCase();
		url = apiUrl + url;
		let headers = {};
		headers = api.oauth.authorizationHeader(apiAuth?.token);
		headers["Content-Type"] = "application/json";

		// emitter.emit('loading', true);
		api.sendRequest(method, url, headers, data, responsetype)
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				if (response?.data?.code === 401) {
					emitter.emit("logout");
				}
				emitter.emit("loading", false);
				reject(response);
			});
	});
};

api.sendRequest = (method, url, headers, data, responsetype = "") => {
	return new Promise((resolve, reject) => {
		let options = {};

		if (responsetype === "blob")
		{
			options = 
			{
				method: method,
				url: url,
				headers: headers,
				responseType: 'blob'
			};
		}
		else
		{
			options = {
				method: method,
				url: url,
				headers: headers,
			};
		}	

		if (method === "GET") options.params = data;
		else options.data = data;

		axios(options)
			.then((response) => {
				resolve(response);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

api.oauth.check = () => {
	return new Promise((resolve, reject) => {
		let authString = sessionStorage.getItem("auth");
		let auth = JSON.parse(authString);
		apiAuth = auth;
		resolve(auth);
	});
};

api.oauth.authorizationHeader = (token) => {
	if (!token) return {};
	return { Authorization: "Bearer " + token.access_token };
};

api.oauth.verifyIfTokenExpired = (token) => {
	let time_now = new Date().getTime();
	let token_expiration = token.expires_at || 0;
	// console.log('verify token expired: ', time_now, token_expiration, time_now > token_expiration);
	return time_now > token_expiration;
};

api.oauth.processNewToken = (token) => {
	const token_expires_in = 1800000;
	token.issued_at = new Date().getTime();
	token.expires_at = token.issued_at + token_expires_in;
	return token;
};

api.oauth.updateAuth = (auth) => {
	apiAuth = auth;
	sessionStorage.setItem("auth", JSON.stringify(auth));
};

api.oauth.logout = () => {
	api.oauth.updateAuth({ logged: false });
};

api.oauth.getAuth = () => {
	return apiAuth;
};

export default api;
